import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";


// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";
import { IMAGES } from "../../../utilities/images";
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from "firebase/auth";
import { auth, db } from "../../../firebase/firebaseConfig";
import { ref, set } from "firebase/database";
import '../../../style.css';
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignUp = () => {
  const appName = useSelector(SettingSelector.app_name);
  const navigate = useNavigate()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [degreeOfStudy, setDegreeOfStudy] = useState("");
  const [hometown, setHometown] = useState("");
  const [smoker, setSmoker] = useState('');
  const [alcohol, setAlcohol] = useState('');
  const [error, setError] = useState('');
  const [cleanlinessLevel, setCleanlinessLevel] = useState("");
  const [social, setSocial] = useState("");
  const [roomType, setRoomType] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    let login = localStorage.getItem("loginUser");
    if (login) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    console.log(birthDate);
  }, [birthDate]);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!gender) {
      setError("Gender is required.");
      return;
    }
    if (!smoker) {
      setError("Smoker status is required.");
      return;
    }
    if (!alcohol) {
      setError("Alcohol preference is required.");
      return;
    }
    if (!cleanlinessLevel) {
      setError("Cleanliness level is required.");
      return;
    }
    if (!social) {
      setError("Social preference is required.");
      return;
    }

    try {
      setError('');
      setIsSubmitted(true);
      // Check if the email is already in use
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      if (signInMethods.length > 0) {
        // Email is already in use
        setError("This email is already registered.");
        return;
      }

      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store user information in Firebase Realtime Database
      await set(ref(db, 'users/' + user.uid), {
        id: user.uid,
        name: name,
        email: user.email,
        password: password,
        degreeOfStudy: degreeOfStudy,
        hometown: hometown,
        smoker: smoker,
        alcohol: alcohol,
        cleanlinessLevel: cleanlinessLevel,
        social: social,
        roomType: roomType,
        gender: gender,
        birthDate: birthDate,
        createdAt: user.metadata.creationTime,
      });

      // Clear input fields
      setEmail('');
      setPassword('');
      setName('');
      setDegreeOfStudy('');
      setHometown('');
      setSmoker('');
      setAlcohol('');
      setCleanlinessLevel('');
      setSocial('');
      setRoomType('');
      setGender('');
      setBirthDate('');

      // Navigate to sign-in page
      navigate("/auth/sign-in");

    } catch (error) {
      console.error("Error creating user:", error);
      setError("Error creating user:", error.message);
    } finally {
      setIsSubmitted(false);
    }
  };

  const handleBirthDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 8) value = value.slice(0, 8); // Limit to 8 digits (YYYYMMDD)

    // Automatically format to YYYY/MM/DD
    if (value.length >= 6) {
      value = `${value.slice(0, 4)}/${value.slice(4, 6)}/${value.slice(6)}`;
    } else if (value.length >= 4) {
      value = `${value.slice(0, 4)}/${value.slice(4)}`;
    }

    setBirthDate(value);
  };

  const validateBirthDate = () => {
    const [year, month, day] = birthDate.split("/").map(Number);

    if (
      year &&
      month &&
      day &&
      year >= 1900 &&
      year <= new Date().getFullYear() &&
      month >= 1 &&
      month <= 12 &&
      day >= 1 &&
      day <= new Date(year, month, 0).getDate()
    ) {
      return true;
    }
    return false;
  };

  const handleBackspace = (e) => {
    // If backspace is pressed, check if last character is a slash and remove it
    if (e.key === "Backspace" && birthDate[birthDate.length - 1] === "/") {
      setBirthDate(birthDate.slice(0, -1)); // Remove the last character (slash)
    }
  };



  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <img
                        src={IMAGES.design1}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Find Your Perfect Place
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        Discover a wide range of student accommodation options in Stellenbosch,
                        <br /> from cozy rooms to spacious apartments.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={IMAGES.design2}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Easy and Efficient
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        Our user-friendly platform makes finding your ideal home a breeze.
                        <br /> Search, compare, and rent with ease.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={IMAGES.design3}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Your Community Awaits
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        Connect with fellow students and build lasting friendships
                        <br /> in your new home away from home.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center" style={{ maxHeight: 'calc(100vh - 60px)', overflowY: 'auto' }}>
                <div
                  className="d-inline-flex align-items-center justify-content-center gap-2 mt-5"
                >
                  <img src={IMAGES.logo} width={50} alt="logo" />
                  <h2 className="logo-title" data-setting="app_name">
                    {appName}
                  </h2>
                </div>
                <p className="mt-3 font-size-16">
                  Find Your Home in Stellenbosch
                </p>
                <Form className="mt-5" onSubmit={handleRegister}>
                  {/* Full Name */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Your Full Name</h6>
                    <Form.Control
                      type="text"
                      className="form-control mb-0"
                      placeholder="Your Full Name"
                      value={name}
                      required
                      autoComplete="name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  {/* Email Address */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Email Address</h6>
                    <Form.Control
                      type="email"
                      className="form-control mb-0"
                      placeholder="marvin@example.com"
                      required
                      value={email}
                      autoComplete="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  {/* Gender */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Gender</h6>
                    <Form.Check
                      type="radio"
                      label="Male"
                      value="Male"
                      checked={gender === 'Male'}
                      onChange={(e) => setGender(e.target.value)}
                      inline
                      required
                    />
                    <Form.Check
                      type="radio"
                      label="Female"
                      value="Female"
                      checked={gender === 'Female'}
                      onChange={(e) => setGender(e.target.value)}
                      inline
                      required
                    />
                  </Form.Group>
                  {/* Degree of Study */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Current Degree of Study</h6>
                    <Form.Control
                      type="text"
                      className="form-control mb-0"
                      placeholder="Current Degree of Study"
                      value={degreeOfStudy}
                      required
                      onChange={(e) => setDegreeOfStudy(e.target.value)}
                    />
                  </Form.Group>
                  {/* Hometown */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Hometown</h6>
                    <Form.Control
                      type="text"
                      className="form-control mb-0"
                      placeholder="Your Hometown"
                      value={hometown}
                      required
                      autoComplete="name"
                      onChange={(e) => setHometown(e.target.value)}
                    />
                  </Form.Group>
                  {/* Smoker */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Smoker</h6>
                    <Form.Check
                      type="radio"
                      label="Yes"
                      value="Yes"
                      checked={smoker === 'Yes'}
                      onChange={(e) => setSmoker(e.target.value)}
                      inline
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      value="No"
                      checked={smoker === 'No'}
                      onChange={(e) => setSmoker(e.target.value)}
                      inline
                    />
                  </Form.Group>
                  {/* Alcohol */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Alcohol</h6>
                    <Form.Check
                      type="radio"
                      label="Yes"
                      value="Yes"
                      checked={alcohol === 'Yes'}
                      onChange={(e) => setAlcohol(e.target.value)}
                      inline
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      value="No"
                      checked={alcohol === 'No'}
                      onChange={(e) => setAlcohol(e.target.value)}
                      inline
                    />
                  </Form.Group>
                  {/* Cleanliness Level */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Cleanliness Level</h6>
                    <Form.Control
                      as="select"
                      className="form-control mb-0"
                      value={cleanlinessLevel}
                      required
                      onChange={(e) => setCleanlinessLevel(e.target.value)}
                    >
                      <option disabled>Select Cleanliness Level</option>
                      <option value="Neat">Neat</option>
                      <option value="Relaxed">Relaxed</option>
                      <option value="Working on it">Working on it</option>
                    </Form.Control>
                  </Form.Group>

                  {/* Social */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Social</h6>
                    <Form.Control
                      as="select"
                      className="form-control mb-0"
                      value={social}
                      required
                      onChange={(e) => setSocial(e.target.value)}
                    >
                      <option disabled>Select Social Type</option>
                      <option value="Extrovert">Extrovert</option>
                      <option value="Ambivert">Ambivert</option>
                      <option value="Introvert">Introvert</option>
                    </Form.Control>
                  </Form.Group>

                  {/* Room-type */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Room Type</h6>
                    <Form.Control
                      as="select"
                      className="form-control mb-0"
                      value={roomType}
                      onChange={(e) => setRoomType(e.target.value)}
                    >
                      <option disabled>Select Room Type</option>
                      <option value="Shared">Shared</option>
                      <option value="Single-room">Single-room</option>
                      <option value="Either">Either</option>
                    </Form.Control>
                  </Form.Group>
                  {/* Date of Birth */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Date of Birth</h6>
                    <Form.Control
                      type="text"
                      className="form-control mb-0"
                      value={birthDate}
                      required
                      onChange={handleBirthDateChange}
                      onKeyDown={handleBackspace} // Trigger backspace handler
                      placeholder="YYYY/MM/DD"
                    />
                    {!validateBirthDate() && birthDate && (
                      <p className="text-danger mt-1">Invalid date. Please check again.</p>
                    )}
                  </Form.Group>
                  {/* Password */}
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Your Password</h6>
                    <Form.Control
                      type="password"
                      className="form-control mb-0"
                      placeholder="Password"
                      required
                      defaultValue="marvin"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Check className="form-check d-inline-block m-0">
                      <Form.Check.Input
                        type="checkbox"
                        required
                        className="form-check-input"
                      />
                      <h6 className="form-check-label fw-500 font-size-14">
                        I accept{" "}
                        <Link
                          className="fw-light ms-1"
                          to="/dashboard/extrapages/terms-of-service"
                        >
                          Terms and Conditions
                        </Link>
                      </h6>
                    </Form.Check>
                  </div>
                  {/* <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"

                  >
                    Sign Up
                  </Button> */}
                  <button
                    variant="primary"
                    type="submit"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                  >
                    {isSubmitted ? "Sign up..." : "Sign up"}
                  </button>
                  {error && (
                    <div className="alert alert-danger mt-2" role="alert" >
                      {error}
                    </div>
                  )
                  }
                  <h6 className="mt-5">
                    Already Have An Account ?{" "}
                    <Link to={"/auth/sign-in"}>Login</Link>
                  </h6>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignUp;
