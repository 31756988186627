import React, { useEffect, useState } from "react";
import {
  Nav,
  Form,
  Card,
  Container,
  Image,
  Dropdown,
  Navbar,
  Modal,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Puff } from "react-loader-spinner";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";
import SearchModal from "../../../search-modal";
import {
  AccomudationState,
  get_Accomudation_Async,
} from "../../../../store/setting/reducers";
import axios from "axios";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "../../../../firebase/firebaseConfig";
import { IMAGES } from "../../../../utilities/images";
import { get, ref } from "firebase/database";

const Header = () => {
  const appName = useSelector(SettingSelector.app_name);

  const loading = useSelector((state) => state.Accomudation.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [active, setActive] = useState("home");
  const [show, setShow] = useState(false);
  const [hotelName, setHotelName] = useState("");
  const [creditCard, setCreditCard] = useState(false);
  const [wifi, setWifi] = useState(false);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [hotelDesc, setHotelDesc] = useState("");
  const [images, setImages] = useState([]);
  const [size, setSize] = useState();
  const [Err, setErr] = useState("");

  const [loader, setLoader] = useState(false)
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const userRef = ref(db, `users/${currentUser.uid}`);

        get(userRef)
          .then((snapshot) => {
            if (snapshot.exists()) {
              const userData = snapshot.val();
              setUser(userData);
            } else {
              console.error("User data not found in the database.");
            }
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
      } else {
        console.log("No user is signed in.");
        setUser(null); // Clear user state if no user is signed in
      }
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);
  // console.log("User Data: ", user)


  const handleImageChange = (e) => {
    for (let index = 0; index < e.target.files.length; index++) {
      const file = e.target.files[index];

      setImages((pre) => [...pre, file]);
      // const Reader = new FileReader();
      // Reader.readAsDataURL(file);

      // Reader.onload = () => {
      //   if (Reader.readyState === 2) {
      //     setImages((pre) => [...pre, Reader.result]);
      //   }
      // };
    }
  };
  const { uploaded, data, uploadedData } = useSelector(AccomudationState);

  const token = JSON.parse(localStorage.getItem("user"))?.token;

  useEffect(() => {
    console.log({ uploaded });
    if (uploaded) {
      dispatch(get_Accomudation_Async());
      setErr("");
      handleClose();
    }
  }, [uploaded]);
  const handleUpload = async (e) => {
    console.log("image", images);
    e.preventDefault();
    if (
      images.length > 0 &&
      address &&
      city &&
      hotelDesc &&
      hotelName &&
      size
    ) {
      const formData = {
        Address: address,
        city: city,
        Country: country,
        HotelDescription: hotelDesc,
        HotelName: hotelName,
        CreditCard: creditCard,
        Wifi: wifi,
        Size: size,
        HotelImages: images,
      };
      setLoader(true)
      //have to do update here ok

      const form = new FormData();
      form.append("Address", formData.Address);
      form.append("city", formData.city);
      form.append("Country", formData.Country);
      form.append("HotelDescription", formData.HotelDescription);
      form.append("HotelName", formData.HotelName);
      form.append("CreditCard", formData.CreditCard);
      form.append("Wifi", formData.Wifi);
      form.append("Size", "Culpa autem eos, ut .");

      images.forEach((image, index) => {
        console.log("image", image)
        form.append("HotelImages", image);
      });
      // return

      const options = {
        method: "POST",
        url: "https://khvw9wf1-3001.inc1.devtunnels.ms/hotel/add-Accomudation",
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: form,
      };

      try {
        const { data } = await axios.request(options);
        console.log(data);
        setLoader(false)
      } catch (error) {
        console.error(error);
        setLoader(false)
      }

      handleClose();
      // dispatch(add_Accomudation_Async(formData));
    } else {
      setErr("All Fields Are Required");
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const minisidebar = () => {
    const sidebarMini = document.getElementsByTagName("ASIDE")[0];
    if (sidebarMini.classList.contains("sidebar-mini")) {
      sidebarMini.classList.remove("sidebar-mini");
    } else {
      sidebarMini.classList.add("sidebar-mini");
    }
  };


  const LogoutNow = () => {
    signOut(auth)
      .then(() => {
        // Remove user data from local storage
        localStorage.removeItem('loginUser');

        // Redirect to sign-in page
        navigate("/auth/sign-in");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  }

  const dropdownContent = document.querySelectorAll(".sub-drop");
  if (dropdownContent) {
    dropdownContent.forEach((element) => {
      setTimeout(() => {
        element.style = "";
      }, 100);
    });
  }

  return (
    <>
      {/* <div className="position-relative"> */}
      <div className="iq-top-navbar border-bottom">
        <Navbar
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          {/* <Navbar className="iq-navbar p-lg-0" sticky="top"> */}
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center pb-2 pb-lg-0 d-xl-none">
              <Link
                to="/"
                className="d-flex align-items-center iq-header-logo navbar-brand d-block d-xl-none"
              >
                {/* <svg
                  width="50"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.67733 9.50001L7.88976 20.2602C9.81426 23.5936 14.6255 23.5936 16.55 20.2602L22.7624 9.5C24.6869 6.16666 22.2813 2 18.4323 2H6.00746C2.15845 2 -0.247164 6.16668 1.67733 9.50001ZM14.818 19.2602C13.6633 21.2602 10.7765 21.2602 9.62181 19.2602L9.46165 18.9828L9.46597 18.7275C9.48329 17.7026 9.76288 16.6993 10.2781 15.8131L12.0767 12.7195L14.1092 16.2155C14.4957 16.8803 14.7508 17.6132 14.8607 18.3743L14.9544 19.0239L14.818 19.2602ZM16.4299 16.4683L19.3673 11.3806C18.7773 11.5172 18.172 11.5868 17.5629 11.5868H13.7316L15.8382 15.2102C16.0721 15.6125 16.2699 16.0335 16.4299 16.4683ZM20.9542 8.63193L21.0304 8.5C22.1851 6.5 20.7417 4 18.4323 4H17.8353L17.1846 4.56727C16.6902 4.99824 16.2698 5.50736 15.9402 6.07437L13.8981 9.58676H17.5629C18.4271 9.58676 19.281 9.40011 20.0663 9.03957L20.9542 8.63193ZM14.9554 4C14.6791 4.33499 14.4301 4.69248 14.2111 5.06912L12.0767 8.74038L10.0324 5.22419C9.77912 4.78855 9.48582 4.37881 9.15689 4H14.9554ZM6.15405 4H6.00746C3.69806 4 2.25468 6.50001 3.40938 8.50001L3.4915 8.64223L4.37838 9.04644C5.15962 9.40251 6.00817 9.58676 6.86672 9.58676H10.2553L8.30338 6.22943C7.9234 5.57587 7.42333 5.00001 6.8295 4.53215L6.15405 4ZM5.07407 11.3833L7.88909 16.2591C8.05955 15.7565 8.28025 15.2702 8.54905 14.8079L10.4218 11.5868H6.86672C6.26169 11.5868 5.66037 11.5181 5.07407 11.3833Z"
                    fill="currentColor"
                  />
                </svg> */}
                <img src={IMAGES.logo} alt="Logo" width={50} />
                <h3
                  className="logo-title d-none d-sm-block"
                  data-setting="app_name"
                >
                  {appName}
                </h3>
              </Link>
              <Link
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={minisidebar}
                to="#"
              >
                <div className="icon material-symbols-outlined iq-burger-menu">
                  {" "}
                  menu{" "}
                </div>
              </Link>
            </div>

            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-between product-offcanvas">
                <div
                  className="offcanvas offcanvas-end shadow-none iq-product-menu-responsive d-none d-xl-block"
                  tabIndex="-1"
                  id="offcanvasBottomNav"
                >
                  <div className="offcanvas-body">
                    <ul className="iq-nav-menu list-unstyled">
                      {/* <li className="nav-item">
                        <Link
                          className={`nav-link menu-arrow justify-content-start ${active === "home" ? 'active' : ''}`}
                          to="/"
                          onClick={() => setActive("home")}
                        >
                          <span className="nav-text">Home</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link menu-arrow justify-content-start ${active === "blog" ? 'active' : ''}`}
                          onClick={() => setActive("blog")}
                          data-bs-toggle="collapse"
                          to="#blogData"
                          role="button"
                          aria-expanded="false"
                          aria-controls="blogData"
                        >
                          <span className="nav-text">Blog</span>
                        </Link>
                        <ul
                          className="iq-header-sub-menu list-unstyled collapse shadow"
                          id="blogData"
                        >
                          <li className="nav-item">
                            <Link className={`nav-link ${active === "Blog Grid" ? 'active' : ''}`} to="/dashboard/blog/blog-grid" onClick={() => setActive("Blog Grid")}>
                              Blog Grid
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className={`nav-link ${active === "Blog List" ? 'active' : ''}`} to="/dashboard/blog/blog-list" onClick={() => setActive("Blog List")}>
                              Blog List
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className={`nav-link ${active === "Blog Detail" ? 'active' : ''}`} to="/dashboard/blog/blog-detail" onClick={() => setActive("Blog Detail")}>
                              Blog Detail
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link menu-arrow justify-content-start ${active === "store" ? 'active' : ''}`}
                          onClick={() => setActive("store")}
                          data-bs-toggle="collapse"
                          to="#storeData"
                          role="button"
                          aria-expanded="false"
                          aria-controls="storeData"
                        >
                          <span className="nav-text">Store</span>
                        </Link>
                        <ul
                          className="iq-header-sub-menu list-unstyled collapse shadow"
                          id="storeData"
                        >
                          <li className="nav-item">
                            <Link className={`nav-link ${active === "Category Grid" ? 'active' : ''}`} to="/dashboards/store/store-category-grid" onClick={() => setActive("Category Grid")}>
                              Category Grid
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className={`nav-link ${active === "Category List" ? 'active' : ''}`} to="/dashboards/store/store-category-list" onClick={() => setActive("Category List")}>
                              Category List
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className={`nav-link ${active === "Store Detail" ? 'active' : ''}`} to="/dashboards/store/store-detail" onClick={() => setActive("Store Detail")}>
                              Store Detail
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className={`nav-link ${active === "Product Detail" ? 'active' : ''}`} to="/dashboards/store/product-detail" onClick={() => setActive("Product Detail")}>
                              Product Detail
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className={`nav-link ${active === "Checkout" ? 'active' : ''}`} to="/dashboards/store/store-checkout" onClick={() => setActive("Checkout")}>
                              Checkout
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <Dropdown
                bsPrefix=" "
                className="iq-search-bar device-search position-relative d-none d-lg-block"


              >
                <Dropdown.Toggle
                  as="form"
                  bsPrefix=" "
                  action="#"
                  className="searchbox open-modal-search"
                >
                  <Link className="search-link" to="#">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="7.82491"
                        cy="7.82495"
                        r="6.74142"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M12.5137 12.8638L15.1567 15.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </Link>
                  <Form.Control
                    type="text"
                    className="text search-input form-control bg-light-subtle"
                    placeholder="Search for people or groups..."
                  />
                  <Link className="d-lg-none d-flex d-none d-lg-block" to="/">
                    <span className="material-symbols-outlined">search12</span>
                  </Link>
                </Dropdown.Toggle>
                <SearchModal />
              </Dropdown> */}
            </div>

            <ul className="navbar-nav navbar-list">
              {/* <Dropdown as="li" className="nav-items">
                <Dropdown.Toggle bsPrefix=" " as="a"
                  to="#"
                  className=" d-flex align-items-center"
                  id="mail-drop"
                >
                  <span className="material-symbols-outlined position-relative">
                    shopping_bag
                    <span className="bg-primary text-white shopping-badge">
                      3
                    </span>
                  </span>
                  <span className="mobile-text d-none ms-3">
                    Shopping Cart
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={`sub-drop header-notification `}
                  data-bs-popper="static"
                >
                  <Card className="shadow m-0">
                    <Card.Header className="px-0 pb-4 mx-5 border-bottom">
                      <div className="header-title">
                        <h5 className="fw-semibold">Shopping Cart</h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0 rounded-0">
                      <div className="item-header-scroll">
                        <Link to="#" className="text-body">
                          <div className="thread d-flex justify-content-between rounded-0">
                            <div>
                              <img
                                className="avatar-45 rounded align-top"
                                src={shop1}
                                alt=""
                                loading="lazy"
                              />{" "}
                              <div className="ms-3 d-inline-block">
                                <h6 className="font-size-14">Casual Shoe</h6>
                                <span className="font-size-14 fw-semibold">
                                  $75.00
                                </span>
                              </div>
                            </div>
                            <span className="material-symbols-outlined">
                              close
                            </span>
                          </div>
                        </Link>
                        <Link to="#" className="text-body">
                          <div className="thread d-flex justify-content-between rounded-0">
                            <div>
                              <img
                                className="avatar-45 rounded align-top"
                                src={shop2}
                                alt=""
                                loading="lazy"
                              />{" "}
                              <div className="ms-3 d-inline-block">
                                <h6 className="font-size-14">
                                  Harsh Reality book
                                </h6>
                                <span className="font-size-14 fw-semibold">
                                  $25.00
                                </span>
                              </div>
                            </div>
                            <span className="material-symbols-outlined">
                              close
                            </span>
                          </div>
                        </Link>
                        <Link to="#" className="text-body">
                          <div className="thread d-flex justify-content-between rounded-0">
                            <div>
                              <img
                                className="avatar-45 rounded align-top"
                                src={shop3}
                                alt=""
                                loading="lazy"
                              />{" "}
                              <div className="ms-3 d-inline-block">
                                <h6 className="font-size-14">
                                  The Raze night book
                                </h6>
                                <span className="font-size-14 fw-semibold">
                                  $15.00
                                </span>
                              </div>
                            </div>
                            <span className="material-symbols-outlined">
                              close
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="m-5 mt-4">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h6 className="font-size-14 fw-bolder">
                            Subtotal:
                          </h6>
                          <span className="font-size-14 fw-semibold text-primary">
                            $115.00
                          </span>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary fw-500 w-100"
                        >
                          View All Products
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown> */}

              <Nav.Item as="li" className="d-lg-none">
                <Dropdown bsPrefix=" " className="iq-search-bar device-search ">
                  <Dropdown.Toggle
                    as="form"
                    bsPrefix=" "
                    className="searchbo open-modal-search"
                  >
                    <Link className="d-lg-none d-flex text-body" to="#">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="7.82491"
                          cy="7.82495"
                          r="6.74142"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></circle>
                        <path
                          d="M12.5137 12.8638L15.1567 15.5"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </Link>
                    <Form.Control
                      type="text"
                      className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                      placeholder="Search here..."
                    />
                  </Dropdown.Toggle>
                  <SearchModal />
                </Dropdown>
              </Nav.Item>

              {/* <Dropdown className="nav-item " as="li">
                <Dropdown.Toggle
                  as="a"
                  bsPrefix=" "
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  id="group-drop"
                >
                  <i className="material-symbols-outlined" onClick={handleShow}>
                    add_circle
                  </i>
                </Dropdown.Toggle>
              </Dropdown> */}

              <Modal
                show={show}
                onHide={handleClose}
                centered
                id="custom-post-modal"
              >
                <div style={{ padding: "20px" }}>
                  <h2 style={{ textAlign: "center" }}>Add Accommodation</h2>
                  <Form onSubmit={handleUpload}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Hotel Images</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={handleImageChange}
                        multiple
                        accept="image/*"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Hotel Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Hotel Name"
                        value={hotelName}
                        onChange={(e) => setHotelName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicHotelDescription"
                    >
                      <Form.Label>HotelDescription</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Hotel Description"
                        value={hotelDesc}
                        onChange={(e) => setHotelDesc(e.target.value)}
                      />
                    </Form.Group>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Credit Card"
                            checked={creditCard}
                            onChange={(e) => setCreditCard(e.target.checked)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Wifi"
                            checked={wifi}
                            onChange={(e) => setWifi(e.target.checked)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter City Name"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Country Name"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Size</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Room Size In SQR"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicAddress">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Provide Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Form.Group>

                    {loading == true || loader == true ? (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Puff
                          visible={true}
                          height="50"
                          width="50"
                          color="#4fa94d"
                          ariaLabel="puff-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ width: "100%" }}
                      >
                        Submit
                      </Button>
                    )}

                    {Err && (
                      <div className="alert alert-danger mt-2" role="alert">
                        {Err}
                      </div>
                    )}
                  </Form>
                </div>
              </Modal>
              {/* <Link to={"/chat"} className=" d-flex align-items-center"> */}
              {/* <Dropdown.Toggle
                  as="a"
                  to="#"
                  className=" d-flex align-items-center"
                  id="mail-drop"
                > */}
              {/* <i className="material-symbols-outlined">mail</i> */}
              {/* <span className="mobile-text d-none ms-3">Message</span> */}
              {/* </Dropdown.Toggle> */}
              {/* </Link> */}
              {/* <Link
                to={"/dashboard/app/notification"}
                className=" d-flex align-items-center"
              > */}
              {/* <Dropdown.Toggle
                  as="a"
                  to="#"
                  className=" d-flex align-items-center"
                  id="mail-drop"
                > */}
              {/* <span className="material-symbols-outlined position-relative">
                  notifications
                  <span className="bg-primary text-white notification-badge"></span>
                </span> */}
              {/* </Dropdown.Toggle> */}
              {/* </Link> */}

              {/* <Nav.Item className="nav-item d-none d-lg-none">
                <Link
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  id="mail-drop-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-symbols-outlined">mail</i>
                  <span className="mobile-text  ms-3">Message</span>
                </Link>
              </Nav.Item> */}

              <Dropdown as="li" className="nav-item user-dropdown">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  className="d-flex align-items-center"
                  id="drop-down-arrow"
                >
                  <Image
                    src={
                      user?.profilePic
                        ? user?.profilePic
                        : IMAGES.avatar
                    }
                    className="img-fluid rounded-circle avatar-48 border border-2 me-3 object-fit-cover"
                    alt="user"
                    loading="lazy"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className={`sub-drop caption-menu `}>
                  <Card className="shadow-none m-0">
                    <Card.Header>
                      <div className="header-title">
                        <h5 className="mb-0">{user?.name}</h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0 ">
                      <div onClick={() => navigate('/dashboard/app/profile')} className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">
                          line_style
                        </span>
                        <div className="ms-3">
                          <div className="mb-0 h6">
                            My Profile
                          </div>
                        </div>
                      </div>
                      <div onClick={() => navigate('/dashboard/app/user-profile-edit')} className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">
                          edit_note
                        </span>
                        <div className="ms-3">
                          <div
                            // to="/dashboard/app/user-profile-edit"
                            className="mb-0 h6"
                          >
                            Edit Profile
                          </div>
                        </div>
                      </div>

                      <div onClick={() => LogoutNow()} className="d-flex align-items-center iq-sub-card">
                        <span className="material-symbols-outlined">login</span>
                        <div className="ms-3">
                          <div className="mb-0 h6">
                            Sign out
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </Container>
          {/* </Navbar> */}
        </Navbar>
      </div>
      {/* </div> */}

      {/* <div
        className={`modal-backdrop fade ${show ? "show" : "d-none"}`}
        onClick={handleClose}
      ></div> */}
    </>
  );
};

export default Header;
